@import "~react-image-gallery/styles/css/image-gallery.css";
/* add font-faces here */
@font-face {
    font-family: "DinProMedium";
    src: url('assets/fonts/DINPro-Medium.woff') format('woff');
}

@font-face {
    font-family: "DinProRegular";
    src: url('assets/fonts/DINRegular.ttf') format('truetype');
}

@font-face {
    font-family: "EncodeSans";
    src: url('assets/fonts/EncodeSans.ttf') format('truetype');
}

